import "../Styles/index.scss";

import { initializePlausible } from "./analytics";
initializePlausible();

import component from "@wagich/svelte-tag";

import RequestQuote from "./request-quote.svelte";
new component({ component: RequestQuote, tagname: "request-quote" });

import LossCalculator from "./loss-calculator.svelte";
new component({ component: LossCalculator, tagname: "loss-calculator" });

let handle = document.getElementById("shaft")!;

async function animateHeader() {
	document.getElementById("dirtyTextureWrap")!.animate([{ height: 0 }], { duration: 3000, fill: "forwards" }).finished
		.then(() => handle.animate([{ opacity: 0 }], { duration: 400, fill: "forwards" }));

	if (window.innerWidth > 1080) {
		await handle.animate([{ bottom: "180px", right: "80%" }], { delay: 210, duration: 2000, fill: "forwards" }).finished;
		handle.animate([{ opacity: 0 }], { duration: 400, fill: "forwards" });

		document.getElementById("hint")!.animate([{ opacity: 0 }], { delay: 600, duration: 400, fill: "forwards" });
		document.getElementById("calculate")!.animate([{ bottom: "700px", right: "10%", maxWidth: "390px" }], { delay: 600, duration: 2000, fill: "forwards" });
		document.getElementById("calculateText")!.animate([{ fontSize: "38px" }], { delay: 600, duration: 2000, fill: "forwards" });
	}
}

handle.addEventListener("click", () => animateHeader());
window.addEventListener("load", () => animateHeader());

// collapsing nav
const whyElement = document.getElementById("why")!;
const navWrapElement = document.getElementById("navWrap")!;
window.addEventListener("scroll", () => {
	if (document.scrollingElement!.scrollTop > whyElement.offsetTop) {
		navWrapElement.classList.add("visible");
	} else {
		navWrapElement.classList.remove("visible");
	}
	document.querySelector(".arrow-bottom")?.classList.add("is-invisible");
}, { passive: true });

// nav link scroll highlighting
let navItems = new Map(Array.from(document.querySelectorAll<HTMLAnchorElement>(".navigation li > a[href^='#']")).map(e => [e.getAttribute("href")?.slice(1), e.parentElement!]));
let observer = new IntersectionObserver((entries, observer) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			let activeNavLink = navItems.get(entry.target.id);
			if (activeNavLink == null) {
				return;
			}
			for (let navLink of navItems.values()) {
				navLink === activeNavLink
					? activeNavLink.classList.add("active")
					: navLink.classList.remove("active");
			}
		}
	})
}, {
	threshold: [0.4]
});

for (let id of navItems.keys()) {
	if (id == null) {
		continue;
	}
	let element = document.getElementById(id);
	if (element == null) {
		continue;
	}
	observer.observe(element);
}

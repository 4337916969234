﻿<script lang="ts">
	import RangeSlider from "svelte-range-slider-pips";

	let plantCapacity = 10;
	let compensationRate = 13.7;
	$: yearlyYield = Math.round(plantCapacity * 10 * compensationRate);
</script>

<div id="example" class="loss-calculator">
	<h2>Beispiel Berechnung</h2>
	<p><i><small>Schieben Sie den Regler hin- und her, um die Werte zu ändern.</small></i></p>
	<div class="halfWidth">
		<h3>Anlagegrösse: <strong><span class="kwpIndach">{plantCapacity}</span> kWp</strong></h3>
		<RangeSlider float pips first="label" last="label" pipstep={2.5} range="min" values={[10]} min={5} max={50} suffix=" kWp" on:change={(e) => (plantCapacity = e.detail.value)} />
	</div>
	<div class="halfWidth">
		<h3>Vergütungssatz: <span class="rappen">{compensationRate}</span> Rappen</h3>
		<RangeSlider float pips first="label" last="label" pipstep={20} range="min" values={[13.7]} min={4} max={40} step={0.1} suffix=" Rp" on:change={(e) => (compensationRate = e.detail.value)} />
	</div>
	<div class="clearBoth" />

	<p />
	<div class="calcRow"><span class="block"><span class="kwpIndach">{plantCapacity}</span> kWp Leistung</span><span class="operator">x</span><span class="block">1000 kWh</span><span class="operator">x</span><span class="block"><span class="rappen">{compensationRate}</span> Rappen</span><span class="operator">=</span><span class="block">ca. CHF <span class="jahresErtrag">{yearlyYield}</span>.– Jahresertrag</span></div>
	<div class="calcRow"><span class="block jahresErtragNew">CHF <span class="jahresErtrag">{yearlyYield}</span>.– Jahresertrag</span><span class="operator">X</span><span class="block">10 %</span><span class="operator">=</span><span class="block">ca. CHF <span id="ertragsVerlust">{Math.round(yearlyYield / 10)}</span>.– Ertragsverlust jährlich</span></div>
	<div class="contentWrap marginTop">
		<div class="contentText">
			<p>Durchschnittlich ist eine drei Jahre alte Anlage zu 5-10 Prozent verschmutzt. Somit haben Sie pro Jahr Mindereinnahmen von bis zu CHF 2000.–</p>
			<p>Diese Angaben gelten nur für das Berner Oberland, da in den städtischen Regionen der Verschmutzungsgrad oft höher ist. Dies hängt unter anderem mit der jährlichen Sonneneinstrahlung sowie der jährlichen Niederschlagsmenge zusammen.</p>
		</div>
		<div class="contentText handwriting blue">
			<p>
				Mindereinnahmen<br />
				bis <span>CHF 2000.–</span>/Jahr!
			</p>
		</div>
	</div>
</div>

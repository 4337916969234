﻿<script lang="ts">
	import { Toast } from "svelma";
	import { slide } from "svelte/transition";

	import CleaningCalculator from "./cleaning-calculator.svelte";
	import QuoteInfo from "./quote-info.svelte";
	import { CleaningCalculatorData, QuoteInfoData, QuoteRequest } from "./models";
	import { Readable } from "svelte/store";
	import ky from "ky";
	import { tick } from "svelte";
	import { trackEvent } from "./analytics";

	export let transactionId: string;

	let quoteInfo: Readable<QuoteInfoData>;
	let quoteInfoValid: Readable<boolean>;

	let calculatorData: Readable<CleaningCalculatorData>;
	let costs: Readable<number | null>;

	let showQuoteInfoForm: boolean = false;
	let isSubmitting: boolean = false;

	let quoteInfoContainerElement: HTMLElement;

	async function submit() {
		try {
			isSubmitting = true;

			let quoteRequest: QuoteRequest = {
				transactionId,
				quotedPrice: $costs,
				...$calculatorData,
				...$quoteInfo,
			};

			let response = await ky.post("/api/inquiry", {
				json: quoteRequest,
				throwHttpErrors: false,
			});

			if (response.ok) {
				Toast.create({ message: "Vielen Dank für Ihre Anfrage.", type: "is-success", position: "is-top", duration: 7000 });
				trackEvent("Anfrage gesendet");
			} else {
				Toast.create({ message: "Ihre Anfrage konnte leider nicht gesendet werden.<br />Bitte kontaktieren Sie uns unter 033 654 15 73 oder info@solarputzer.ch", type: "is-danger", position: "is-top", duration: 140000 });
			}
		} finally {
			isSubmitting = false;
		}
	}

	async function gotoQuoteInfoForm() {
		showQuoteInfoForm = true;
		await tick();
		window.scrollTo({
			behavior: "smooth",
			top: quoteInfoContainerElement.offsetTop + 600
		});
	}
</script>

<h2 class="title is-2">Reinigungskosten berechnen</h2>
<p>Berechnen Sie jetzt die Reinigungskosten für Ihre Solaranlage. Füllen Sie das folgende Formular aus und senden Sie uns auf Wunsch direkt eine Anfrage für eine verbindliche Offerte.</p>

<CleaningCalculator bind:data={calculatorData} bind:costs />

{#if showQuoteInfoForm == false}
	<div transition:slide>
		{#if $costs != null}
			<p class="has-text-centered my-4">
				<button class="button is-primary" on:click={() => (showQuoteInfoForm = true)}>Anfrage senden</button>
			</p>
		{:else}
			<p class="has-text-centered my-4">
				<button class="button is-primary" on:click={gotoQuoteInfoForm}>Ich möchte eine Anfrage ohne Berechnung machen</button>
			</p>
		{/if}
	</div>
{:else}
	<div class="request-quote__quote-info has-background-white-bis p-5" transition:slide bind:this={quoteInfoContainerElement}>
		<QuoteInfo {transactionId} bind:data={quoteInfo} bind:isValid={quoteInfoValid} />

		<div class="field mt-3">
			<div class="control">
				<button class="button is-primary" class:is-loading={isSubmitting} disabled={!$quoteInfoValid} on:click={submit}>Anfrage senden</button>
			</div>
		</div>
	</div>
{/if}

<script lang="ts">
	type T = $$Generic;

	import { createEventDispatcher, tick } from "svelte";
	const dispatch = createEventDispatcher();

	export let query: string = "";
	export let items: T[] = [];
	export let selectedItem: T | null = null;
	export let placeholder: string = "";
	export let isLoading: boolean = false;
    export let displayNameAccessor: (item: T | null) => string = x => x as string;

	let showDropdown: boolean = false;
	let inputElement: HTMLInputElement;

	$: {
		if (items.length > 0) {
			showDropdown = true;
		}
	}

	function onInput() {
		dispatch("input", query);
	}

	function onSelected(item: T) {
        selectedItem = item;
		showDropdown = false;
		dispatch("selected", item);
		query = "";
	}

	async function onClear() {
		selectedItem = null;
		query = "";
		dispatch("cleared");

		await tick();
		inputElement.focus();
	}
</script>

<div class="autocomplete">
	<div class="dropdown" class:is-active={showDropdown}>
		<div class="dropdown-trigger">
			{#if selectedItem == null}
				<div class="control" class:is-loading={isLoading}>
					<input bind:this={inputElement} class="input autocomplete__input" type="text" {placeholder} autocomplete="disable-autocomplete" bind:value={query} on:input={onInput} />
				</div>
			{:else}
				<div class="control has-icons-right">
					<input class="input autocomplete__input" type="text" readonly value={displayNameAccessor(selectedItem)} />
					<span class="icon is-small is-right is-clickable autocomplete__clear-button" on:click={onClear}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Löschen</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
                    </span>
				</div>
			{/if}
		</div>
		<div class="dropdown-menu" id="dropdown-menu" role="menu">
			<div class="dropdown-content">
				{#each items as item}
					<a class="dropdown-item" on:click|preventDefault={onSelected(item)}>
						<slot name="item" {item}>{displayNameAccessor(item)}</slot>
					</a>
				{/each}
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	.autocomplete {
		width: 100%;
	}

	.dropdown,
	.dropdown-trigger {
		width: 100%;
	}

    .autocomplete__clear-button svg {
        width: 1.5rem;
        height: 1.5rem;
    }
</style>

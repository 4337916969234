﻿<script lang="ts">
	import { Select } from "svelma";
	import { create, test, enforce, only } from "vest";
	import MapboxGeocoder, { GeocodeFeature } from "@mapbox/mapbox-sdk/services/geocoding";
	import MapboxDirections from "@mapbox/mapbox-sdk/services/directions";
	import throttle from "lodash/throttle";
	import { tippy } from "svelte-tippy";
	import RangeSlider from "svelte-range-slider-pips";

	import { CleaningCalculatorData, FallProtectionType, PlantType } from "./models";
	import { StoreState, ValidatingStore } from "./validating-store";
	import Autocomplete from "./autocomplete.svelte";
	import { Readable, derived } from "svelte/store";

	const validationFunc = (data: CleaningCalculatorData) => {};
	const mapboxAccessToken = "pk.eyJ1Ijoid2FnbmVyZ3JhcGhpY3MiLCJhIjoiY2xoMGJlbHhsMDd3ZjNxb2hzNDd3OXZ1NyJ9.tdABJ-C6CnBPvGYT53GaRQ";

	const initialFormData: CleaningCalculatorData = {
		location: null,
		address: null,
		distanceToLocation: null,
		plantType: null,
		plantSize: null,
		accessLength: null,
		gutterHeight: null,
		fallProtection: null,
	};

	type CostFunction = (store: StoreState<CleaningCalculatorData>, currentCost: number) => number | null;

	interface CalculatorItem<T> {
		value: T;
		text: string;
		imageUrl?: string;
		costFunction: CostFunction;
	}

	const plantTypes: CalculatorItem<PlantType>[] = [
		{ value: PlantType.ParallelToRoof, text: "Dachparallel", imageUrl: "/images/dachparallel.png", costFunction: (s, c) => c + 120 },
		{ value: PlantType.FlatRoofSouth, text: "Flachdach Süd", imageUrl: "/images/flachdach_sud.png", costFunction: (s, c) => c + 80 },
		{ value: PlantType.FlatRoofEastWest, text: "Flachdach Ost-West", imageUrl: "/images/flachdach_ost-west.png", costFunction: (s, c) => c + 100 },
	];
	const accessLengths: CalculatorItem<number>[] = [
		{ value: 0, text: "direkt", costFunction: (s, c) => c + 40 },
		{ value: 15, text: "bis 15m", costFunction: (s, c) => c + 100 },
		{ value: 30, text: "bis 30m", costFunction: (s, c) => c + 180 },
		{ value: 999, text: "über 30m", costFunction: (s, c) => null },
	];
	const gutterHeights: CalculatorItem<number>[] = [
		{ value: 2.5, text: "bis 2.5m", costFunction: (s, c) => c },
		{ value: 5, text: "bis 5m", costFunction: (s, c) => c + 120 },
		{ value: 7, text: "bis 7m", costFunction: (s, c) => c + 160 },
		{ value: 10, text: "bis 10m", costFunction: (s, c) => c + 200 },
		{ value: 999, text: "über 10m", costFunction: (s, c) => null },
	];
	const fallProtectionTypes: CalculatorItem<FallProtectionType>[] = [
		{
			value: FallProtectionType.None,
			text: "Keine",
			imageUrl: "/images/dachparallel.png",
			costFunction: (s, c) => (s.plantSize.value != null ? c + s.plantSize.value * 2 : null),
		},
		{
			value: FallProtectionType.Guardrails,
			text: "Fixer Zaun",
			imageUrl: "/images/fixer-zaun.png",
			costFunction: (s, c) => c,
		},
		{
			value: FallProtectionType.Lifeline,
			text: "Seil",
			imageUrl: "/images/seil.png",
			costFunction: (s, c) => (s.plantSize.value != null ? c + s.plantSize.value * 0.5 : null),
		},
		{
			value: FallProtectionType.SinglePointAnchors,
			text: "Einzelanschlagpunkte",
			imageUrl: "/images/einzelschlagpunkte.png",
			costFunction: (s, c) => (s.plantSize.value != null ? c + s.plantSize.value * 0.7 : null),
		},
	];

	const store = new ValidatingStore<CleaningCalculatorData>(initialFormData, validationFunc);

	export const data = derived(store, $state => {
		return Object.fromEntries(Object.entries($state).map(([prop, x]) => [prop, x.value])) as unknown as CleaningCalculatorData;
	});
	export const isValid = derived(store, _ => store.result.isValid());

	const geocoder = MapboxGeocoder({
		accessToken: mapboxAccessToken,
	});
	const router = MapboxDirections({
		accessToken: mapboxAccessToken,
	});

	let isGeocoderBusy = false;
	let geocodeFeatures: GeocodeFeature[] = [];
	async function geocodeAddress(e: CustomEvent<string>) {
		try {
			isGeocoderBusy = true;
			let response = await geocoder
				.forwardGeocode({
					query: e.detail,
					countries: ["ch"],
					types: ["address"],
					language: ["de"],
				})
				.send();
			geocodeFeatures = response.body.features;
		} catch (e) {
			console.log(e);
		} finally {
			isGeocoderBusy = false;
		}
	}
	const geocodeAddressThrottled = throttle(geocodeAddress, 500);

	async function onGeocodeFeatureSelected(e: CustomEvent<GeocodeFeature>) {
		store.update((state) => {
			state.location.value = { type: "Point", coordinates: e.detail.center };
			state.address.value = (e.detail as any).place_name_de;
			return state;
		});

		try {
			let response = await router
				.getDirections({
					profile: "driving",
					waypoints: [{ coordinates: [7.6724971, 46.6894627] }, { coordinates: e.detail.center as [number, number] }],
					language: "de",
				})
				.send();

			if (response.body.routes.length > 0) {
				store.update((state) => {
					state.distanceToLocation.value = response.body.routes[0].distance;
					return state;
				});
			}
		} catch (e) {
			console.log(e);
		}
	}

	function onLocationCleared() {
		store.update(state => {
			state.location.value = null;
			state.address.value = null;
			state.distanceToLocation.value = null;
			return state;
		});
	}

	function formatGeocodeItemForDisplay(item: GeocodeFeature | null): string {
		return (item as any)?.place_name_de ?? "";
	}

	function formatPlantSizeForHandle(value: number): string {
		if (value > 300) {
			return `über 300 m²`;
		} else {
			return `bis ${value} m²`;
		}
	}

	function formatPlantSizeForPip(value: number): string {
		const pipValues = [25, 50, 100, 150, 200, 250, 300];

		if (value > 300) {
			return `über 300 m²`;
		} else if (pipValues.includes(value)) {
			return `bis ${value} m²`;
		} else {
			return "";
		}
	}

	const costFunctions: CostFunction[] = [
		(s, c) => {
			if (s.distanceToLocation.value == null) {
				return null;
			}
			let roundedDistanceInKm = (Math.round(s.distanceToLocation.value / 1000 / 20) + 1) * 20;
			if (roundedDistanceInKm <= 20) {
				return c + 70;
			} else if (roundedDistanceInKm <= 40) {
				return c + 140;
			} else if (roundedDistanceInKm <= 60) {
				return c + 220;
			} else if (roundedDistanceInKm <= 100) {
				return c + 360;
			} else if (roundedDistanceInKm <= 150) {
				return c + 540;
			} else if (roundedDistanceInKm <= 200) {
				return c + 720;
			} else if (roundedDistanceInKm <= 250) {
				return c + 900;
			} else {
				return null;
			}
		},
		(s, c) => plantTypes.find((x) => x.value === s.plantType.value)?.costFunction(s, c) ?? null,
		(s, c) => {
			if (s.plantSize.value == null) {
				return null;
			} else if (s.plantSize.value <= 25) {
				return c + 50;
			} else if (s.plantSize.value <= 50) {
				return c + 110;
			} else if (s.plantSize.value <= 100) {
				return c + 210;
			} else if (s.plantSize.value <= 150) {
				return c + 320;
			} else if (s.plantSize.value <= 200) {
				return c + 420;
			} else if (s.plantSize.value <= 250) {
				return c + 530;
			} else if (s.plantSize.value <= 300) {
				return c + 630;
			} else {
				return null;
			}
		},
		(s, c) => accessLengths.find((x) => x.value === s.accessLength.value)?.costFunction(s, c) ?? null,
		(s, c) => gutterHeights.find((x) => x.value === s.gutterHeight.value)?.costFunction(s, c) ?? null,
		(s, c) => fallProtectionTypes.find((x) => x.value === s.fallProtection.value)?.costFunction(s, c) ?? null,
	];

	export const costs = derived(store, ($state) => {
		let sum = 0;
		for (let costFn of costFunctions) {
			let tempSum = costFn($state, sum);
			if (tempSum == null) {
				return null;
			}
			sum = tempSum;
		}
		return sum;
	});
</script>

<div class="cleaning-calculator py-5">
	<div class="field">
		<label for="fullname" class="label">Standort der Anlage</label>
		<div class="control">
			<Autocomplete placeholder="Adresse eingeben …" isLoading={isGeocoderBusy} items={geocodeFeatures} displayNameAccessor={formatGeocodeItemForDisplay} on:input={geocodeAddressThrottled} on:selected={onGeocodeFeatureSelected} on:cleared={onLocationCleared} />
		</div>
		{#each $store.location.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="field mb-0 pb-2">
		<label for="plantType" class="label">Anlagetyp</label>
	</div>
	<div class="field is-grouped pt-0 is-flex-wrap-wrap">
		{#each plantTypes as item}
			<div class="control pictureradio">
				<input class="is-checkradio" type="radio" name="plantType" id={`plantType_${item.value}`} value={item.value} bind:group={$store.plantType.value} />
				<label class="pictureradio__label" for={`plantType_${item.value}`}>
					<div class="pictureradio__label-inner">
						<img class="pictureradio__image" src={item.imageUrl} alt={item.text} />
						<span class="pictureradio__text">{item.text}</span>
					</div>
				</label>
			</div>
		{/each}
		{#each $store.plantType.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="field mb-0 pb-2">
		<label for="plantSize" class="label">Anlagegrösse</label>
		<div class="control pb-4">
			<RangeSlider float pips all="label" range="min" values={[0]} min={25} max={325} step={25} springValues={{ stiffness: 0.15, damping: 0.5 }} formatter={formatPlantSizeForPip} handleFormatter={formatPlantSizeForHandle} on:change={(e) => ($store.plantSize.value = e.detail.value)} />
		</div>
		{#each $store.plantSize.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="field mb-0 pb-2">
		<label for="accessLength" class="label">Zufahrt/Gehweg</label>
	</div>
	<div class="field is-grouped pt-0">
		{#each accessLengths as item}
			<div class="control">
				<input class="is-checkradio" type="radio" name="accessLength" id={`accessLength_${item.value}`} value={item.value} bind:group={$store.accessLength.value} />
				<label for={`accessLength_${item.value}`}>{item.text}</label>
			</div>
		{/each}
		{#each $store.accessLength.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="field mb-0 pb-2">
		<label for="gutterHeight" class="label">Traufhöhe <span class="icon is-small" use:tippy={{ content: `<img src="images/traufhoehe.jpg" class="tooltipimage" width="380" height="400" />`, allowHTML: true }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Information</title><path d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg></span></label>
	</div>
	<div class="field is-grouped pt-0">
		{#each gutterHeights as item}
			<div class="control">
				<input class="is-checkradio" type="radio" name="gutterHeight" id={`gutterHeight_${item.value}`} value={item.value} bind:group={$store.gutterHeight.value} />
				<label for={`gutterHeight_${item.value}`}>{item.text}</label>
			</div>
		{/each}
		{#each $store.gutterHeight.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="field mb-0 pb-2">
		<label for="fallProtection" class="label">Absturzsicherung</label>
	</div>
	<div class="field is-grouped pt-0 is-flex-wrap-wrap">
		{#each fallProtectionTypes as item}
			<div class="control pictureradio">
				<input class="is-checkradio" type="radio" name="fallProtection" id={`fallProtection_${item.value}`} value={item.value} bind:group={$store.fallProtection.value} />
				<label class="pictureradio__label" for={`fallProtection_${item.value}`}>
					<div class="pictureradio__label-inner">
						<img class="pictureradio__image" src={item.imageUrl} alt={item.text} />
						<span class="pictureradio__text">{item.text}</span>
					</div>
				</label>
			</div>
		{/each}
		{#each $store.fallProtection.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="calculation-result">
		{#if $store.plantSize.value === 325}
			Für Ihre Anlage mit dieser Grösse unterbreiten wir Ihnen gerne ein Individualangebot!
		{:else if $store.accessLength.value === 999}
			Die Zufahrt kann nicht berechnet werden. Gerne unterbreiten wir Ihnen ein Individualangebot!
		{:else if $store.gutterHeight.value === 999}
			Die Kalkukation kann aufgrund der Traufhöhe nicht berechnet werden. Gerne unterbreiten wir Ihnen ein Individualangebot!
		{:else if ($store.distanceToLocation.value ?? 0) > 100000}
			Sie befinden sich ausserhalb unseres Regulärgebietes. Wir bitten um eine Direktanfrage.
		{:else if $costs != null}
			Ihre Reinigungskosten: <br /><span>CHF {$costs}.00</span>
		{/if}
	</div>
</div>

﻿export interface QuoteRequest {
	transactionId: string;
	quotedPrice: number | null;
}

export interface CleaningCalculatorData {
	location: GeoJSON.Point | null;
	address: string | null;
	distanceToLocation: number | null;
	plantType: PlantType | null;
	plantSize: number | null;
	accessLength: number | null;
	gutterHeight: number | null;
	fallProtection: FallProtectionType | null;
}

export interface QuoteInfoData {
	fullname: string;
	email: string;
	phone: string;
	message: string;
}

export enum PlantType {
	ParallelToRoof = "parallelToRoof",
	FlatRoofSouth = "flatRoofSouth",
	FlatRoofEastWest = "flatRoofEastWest",
}

export enum FallProtectionType {
	None = "none",
	Guardrails = "guardrails",
	Lifeline = "lifeline",
	SinglePointAnchors = "singlePointAnchors",
}

﻿import Plausible, { EventOptions, PlausibleOptions } from "plausible-tracker";

const plausible = Plausible({
	domain: "solarputzer.ch",
});

export function initializePlausible() {
	plausible.trackPageview();
}

export function trackEvent(eventName: string, options?: EventOptions, eventData?: PlausibleOptions) {
	try {
		plausible.trackEvent(eventName, options, eventData);
	} catch {}
}

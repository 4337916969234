﻿<script lang="ts">
	import { create, test, enforce, only } from "vest";
	import { derived } from "svelte/store";
	import { onMount } from "svelte";
	import Dropzone from "dropzone";

	import { ValidatingStore } from "./validating-store";
	import { QuoteInfoData } from "./models";

	export let transactionId: string;

	const validationFunc = (data: QuoteInfoData) => {
		test("fullname", "Bitte geben Sie Ihren Namen an.", () => {
			enforce(data.fullname).isNotEmpty();
		});
		test("phone", "Bitte geben Sie Ihre Telefonnummer an.", () => {
			enforce(data.phone).isNotEmpty();
		});
		test("email", "Bitte geben Sie Ihre E-Mail-Adresse an.", () => {
			enforce(data.email).isNotEmpty();
		});
		test("email", "Bitte geben Sie eine gültige E-Mail-Adresse an.", () => {
			enforce(data.email).matches(/.+\@.+\..+/);
		});
	};

	let dropzoneElement: HTMLElement;
	let dropzone: Dropzone;

	const initialFormData: QuoteInfoData = {
		fullname: "",
		phone: "",
		email: "",
		message: "",
	};

	let store = new ValidatingStore<QuoteInfoData>(initialFormData, validationFunc);

	export const data = derived(store, ($state) => {
		return Object.fromEntries(Object.entries($state).map(([prop, x]) => [prop, x.value])) as unknown as QuoteInfoData;
	});
	export const isValid = derived(store, (_) => store.result.isValid());

	onMount(() => {
		dropzone = new Dropzone(dropzoneElement, {
			url: `/api/inquiry/${transactionId}`,
			autoProcessQueue: true,
			paramName: "file",
			maxFiles: 10,
			maxFilesize: 10, // MB,
			
			dictDefaultMessage: "",
			dictFallbackMessage: "Ihr Browser unterstützt Drag&Drop Dateiuploads nicht.",
			dictFallbackText: "Benutzen Sie das Formular um Ihre Dateien hochzuladen.",
			dictFileTooBig: "Die Datei ist zu gross. Die maximale Dateigröße beträgt {{maxFilesize}}MB.",
			dictInvalidFileType: "Eine Datei dieses Typs kann nicht hochgeladen werden.",
			dictResponseError: "Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt.",
			dictCancelUpload: "Hochladen abbrechen",
			dictCancelUploadConfirmation: "ul",
			dictRemoveFile: "Datei entfernen",
			dictMaxFilesExceeded: "Sie können keine weiteren Dateien mehr hochladen."
		});
	});
</script>

<div class="quote-info">
	<div class="field">
		<label for="fullname" class="label">Vor- und Nachname</label>
		<div class="control">
			<input id="fullname" class="input" autocomplete="name" bind:value={$store.fullname.value} on:blur={() => $store.fullname.touch()} class:is-danger={$store.fullname.hasErrors} />
		</div>
		{#each $store.fullname.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>
	<div class="field">
		<label for="email" class="label">E-Mail</label>
		<div class="control">
			<input id="email" class="input" autocomplete="email" bind:value={$store.email.value} on:blur={() => $store.email.touch()} class:is-danger={$store.email.hasErrors} />
		</div>
		{#each $store.email.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>
	<div class="field">
		<label for="phone" class="label">Telefon</label>
		<div class="control">
			<input id="phone" class="input" autocomplete="phone" bind:value={$store.phone.value} on:blur={() => $store.phone.touch()} class:is-danger={$store.phone.hasErrors} />
		</div>
		{#each $store.phone.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>

	<div class="field">
		<div class="label">Fotos der Anlage</div>
		<div class="control">
			<div class="dropzone is-clearfix" bind:this={dropzoneElement}>
				<p class="help">
					<strong>Fügen Sie hier Fotos von der Anlage, der Anfahrt, vom Gebäude, etc. hinzu.</strong><br />
					Ziehen Sie einfach die Bilder von Ihrem Computer in dieses Feld (Drag &amp; Drop) oder klicken Sie um Dateien auszuwählen.
				</p>
			</div>
		</div>
	</div>

	<div class="field">
		<label for="message" class="label">Nachricht <em>(optional)</em></label>
		<div class="control">
			<textarea id="message" class="textarea" bind:value={$store.message.value} on:blur={() => $store.message.touch()} class:is-danger={$store.message.hasErrors} />
		</div>
		{#each $store.message.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>
</div>

﻿import { create, test, enforce, only, Suite, SuiteResult } from "vest";
import { readable, Readable, Writable, writable, get, derived, Subscriber, Unsubscriber, Updater } from "svelte/store";

export type StoreState<T> = { [P in keyof T]-?: ValidatingStoreItem<T[P], T> }

class ValidatingStoreItem<TProp, TState> {
	private store: ValidatingStore<TState>;

	readonly propName: keyof TState & string;
	isDirty: boolean = false;
	value: TProp;

	get errors(): string[] {
		if (!this.isDirty) {
			return [];
		}
		return this.store.result.getErrors(this.propName);
	}

	get hasErrors(): boolean {
		return this.isDirty
			&& this.store.result.hasErrors(this.propName);
	}

	get isValid(): boolean {
		return this.store.result.isValid(this.propName);
	}

	constructor(propName: keyof TState & string, value: TProp, store: ValidatingStore<TState>) {
		this.propName = propName;
		this.value = value;
		this.store = store;
	}

	touch() {
		this.store.touch(this.propName);
	}
}

export class ValidatingStore<TState> implements Writable<StoreState<TState>> {
	private initialData: TState;
	private suite: Suite<(data: TState) => void>;
	private store: Writable<StoreState<TState>>;

	result: SuiteResult;

	constructor(initialData: TState, suiteCallback: (data: TState) => void) {
		this.initialData = initialData;

		this.suite = create(suiteCallback);
		this.result = this.suite.get();

		this.store = writable(this.createItems(initialData));
	}

	subscribe(run: Subscriber<StoreState<TState>>): Unsubscriber {
		return this.store.subscribe(run);
	}

	set(value: StoreState<TState>): void {
		this.store.set(value);
		let data = this.getData();
		this.result = this.suite(data);
	}

	update(updater: Updater<StoreState<TState>>): void {
		this.store.update(updater);
		let data = this.getData();
		this.result = this.suite(data);
	}

	validate(): boolean {
		this.update(state => {
			for (let item of Object.values(state)) {
				(item as ValidatingStoreItem<TState, this>).isDirty = true;
			}
			return state;
		});

		let data = this.getData();
		this.result = this.suite(data);
		return this.result.isValid();
	}

	touch<K extends keyof TState>(prop: K) {
		this.store.update(state => {
			let propValue = state[prop].value;
			// only mark props as dirty when they are non-empty
			if (propValue != null && propValue != "") {
				state[prop].isDirty = true;
			}
			return state;
		});
	}

	clear() {
		this.set(this.createItems(this.initialData));
	}

	getData(): TState {
		let data: any = {};
		for (let [prop, item] of Object.entries(get(this.store))) {
			data[prop] = (item as any).value;
		}
		return data;
	}

	private createItems(data: any): StoreState<TState> {
		let items: any = {};
		for (let [prop, value] of Object.entries(data)) {
			items[prop] = new ValidatingStoreItem(prop as any, value, this);
		}
		return items;
	}
}
